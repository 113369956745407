import request from './request'

// 商品列表
export const getGoodsList = (data) => {
  return request.post('/rame/gsfms/getGoods', data)
}
// 编辑商品
export const editGoods = (data) => {
  return request.post('/rame/gsfms/updateGoods', data)
  // return request.post('/jf-tech/v1/updateGoods', data)
}
// 新建商品
export const addGoods = (data) => {
  // return request.post('/jf-tech/v1/addGoods', data)
  return request.post('/rame/gsfms/addGoods', data)
}
// 删除商品
export const delGoods = (data) => {
  return request.post('/rame/gsfms/deleteGoods', data)
  // return request.post('/jf-tech/v1/deleteGoods', data)
}

export const get3DBallData = (data) => {
  return request.get('/get3DBallData', data)
}
// 删除图片
export const delImgItem = (data) => {
  return request.post('/jf-tech/v1/deleteGoodsImages', data)
}
// 活动查询
export const getActivities = (data) => {
  // return request.post('/jf-tech/v1/getActivities', data)
  return request.post('/rame/gsfms/getActivities', data)
}

export const sendChatMessage = (data) => {
  return request.post('/jf-tech/v1/chat', data)
}

// 获取商品类型
export const getGoodsTypes = (data) => {
  return request.get('/v1/goods/getGoodsTypes', data)
}

// 更新商品型号
export const updateGoodsSorts = (data) => {
  return request.post('/rame/gsfms/updateGoodsSorts', data)
}

// 新增商品型号
export const addGoodsSorts = (data) => {
  return request.post('/rame/gsfms/addGoodsSorts', data)
  // return request.post('/jf-tech/v1/addGoodsSorts', data)
}
// 删除商品型号
export const deleteGoodsSorts = (data) => {
  return request.post('/rame/gsfms/delGoodsSorts', data)
}
// https://www.ipolarx.com/api/rame/gsfms/getGoodsSorts
// 查询商品型号
export const getGoodsSorts = (data) => {
  return request.post('/rame/gsfms/getGoodsSorts', data)
}
